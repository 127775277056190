<template>
  <van-cell-group>
    <van-cell
      v-for="(item, index) in list"
      :key="index"
      :title="item.productName"
    >
      <template #label>
        <div class="f-cb">
          <div>SKU{{ $t('colon') + item.productCode }}</div>
          <div>
            {{ $t('productCapacity') + $t('colon') + item.productCapacity }}
          </div>
        </div>
        <van-tag :type="item.caveatLevel | formatType">
          {{ $t('productStockNum') + $t('colon') + item.productStockNum }}
        </van-tag>
      </template>
    </van-cell>
  </van-cell-group>
</template>
<script>
import util from '@/utils/util'

export default {
  data() {
    return {
      list: [],
      productList: [],
    }
  },
  created() {
    this.vmReplenishDemand()
  },
  methods: {
    async vmReplenishDemand() {
      const { nodeId, vmCode } = this.$route.query
      const { data } = await this.$api.vmReplenishDemand({
        vmCodes: [vmCode],
        nodeId,
      })
      console.log('vmReplenishDemand===>', data)
      this.list = data.sort(
        (a, b) =>
          util.getWarnStat(b.caveatLevel) - util.getWarnStat(a.caveatLevel)
      )
      this.productList = this.list.map((v) => ({
        productName: v.productName,
        productCode: v.productCode,
        vmCode: v.vmCode,
      }))
    },
    toDetail(index) {
      // console.log(index)
      sessionStorage.setItem('productList', JSON.stringify(this.productList))
      sessionStorage.setItem('index', index)
      this.$router.push({ name: 'WarningChart' })
    },
  },
}
</script>